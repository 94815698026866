body {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'LuloClean';
  src: url('./Component/Shared/fonts/Lulo-Clean-Font/@font-face/39a2c7f346d5cfae7045aeb2fb50d9ad.woff2') format('woff2'),
       url('./Component/Shared/fonts/Lulo-Clean-Font/@font-face/39a2c7f346d5cfae7045aeb2fb50d9ad.woff') format('woff'),
       url('./Component/Shared/fonts/Lulo-Clean-Font/@font-face/39a2c7f346d5cfae7045aeb2fb50d9ad.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}