.App {
  width: 100%;
  margin: 0;
  padding: 0;

}

.BrDesk {
  display: inline;
}

.BrMob {
  display: none;
}

/* Small screens */
@media (max-width: 767px) {

  .BrDesk {
    display: none;
  }

  .BrMob {
    display: inline;
  }
}