.ReviewsCont {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  h2 {
    font-size: 40px;
    line-height: 1.3em;
    font-family: 'LuloClean';
  }
}

.ReviewMainDiv {
  margin-top: 20px;
  width: 50%;
}

/* Small screens */
@media (max-width: 767px) {
  .ReviewsCont {
    margin-top: 30px;
    h2 {
      text-align: center;
      font-size: 25px;
    }
  }

  .ReviewMainDiv {
    margin-top: 20px;
    width: 90%;
  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1200px) {}

/* Large screens
@media (min-width: 1201px) {
} */