/* card css */
.PropCard {
  width: 306px;
  background-color: white;
  box-shadow: 0 14px 20px 0px rgba(0, 0, 0, 0.1);
  img{
    width: 100%;
  }
}
.PropInfoDiv{
  background-color: white;
  position: relative;
  text-align: left;
  height: 42%;
}
.CardHeading {
  position: absolute;
  width: 60%;
  text-align: start;
  top: -30px;
  background-color: white;
  padding: 5px 20px 5px 10px;

  h4 {
    margin: 0;
    font-family: 'LuloClean';
    font-size: 16px;
  }
}
.CardTags {
  background-color: #90A9F5;
  font-family: 'LuloClean';
  border-radius: 5px;
  padding: 5px 15px 5px 10px;
  font-size: 12px;
  color: white;
  margin-left: 15px;
}
.PropInfo{
  display: flex;
  width: 100%;
  padding-left: 15px;
  gap: 10px;
  margin-top: 20px;
  div{
    text-align: left;
  }
  strong{
    font-size: 16px;
    font-family: Helvetica, sans-serif;
    line-height: 1.5em;
  }
  p{
    color: #577083;
    font-size: 15px;
    font-family: Helvetica, sans-serif;
    line-height: 1.5em;
    margin-bottom: 0;
  }
  h3{
    color: #536EB7;
    font-size: 20px;
    font-family: Helvetica;
    line-height: 1.5em;
    margin: 0;

  }
}
/* Small screens */
@media (max-width: 767px) {
  .PropCard {
    width:90%;
    margin-bottom: 20px;
    box-shadow: 0 14px 20px 25px rgba(0, 0, 0, 0.1);
  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1200px) {}

/* Large screens
@media (min-width: 1201px) {
} */