.HeaderDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 42px 0 37px 0;
}

.BrandIcon {
    width: 52px;
    height: 52px;
}

.HeaderHeading {
    font-family: 'LuloClean';
    font-size: 35px;
    margin: 0;
    letter-spacing: 0.1em;
}

.HrLine {
    width: 90%;
    margin-top: 0;
    margin-bottom: 20px;
    height: 1px;
    background-color: black;
    border: none;
}

/* Small screens */
@media (max-width: 767px) {
    .HeaderDiv {
        flex-direction: column;
        text-align: center;
    }
    .HeaderHeading{
        font-size: 18px;
    }
    .HrLine{
        display: none;
    }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1200px) {}

/* Large screens
@media (min-width: 1201px) {
} */