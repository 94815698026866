.FooterCont{
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  margin:50px auto 100px auto;
}
.FooterIcon{
 width: 150px;
}
.ContactFooter {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background-color: white;
  border: none;
  border-radius: 10px;
  font-family: Helvetica, sans-serif;
  color: black;
  line-height: 1.7em;
  a {
    color: black;
  }
}
@media (max-width: 767px) {
  .FooterCont {
    flex-direction: column-reverse;
  }
  .FooterIcon{
    margin-inline: auto;
    width: 100px;
  }
}