.collage-carousel {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  margin-top: 100px;
}

.collage-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(4, 130px);
  grid-auto-columns: minmax(110px, 1fr);
  gap: 5px;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
}

.collage-container::-webkit-scrollbar {
  display: none;
}

.collage-item {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.collage-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  z-index: 10;
  font-size: 18px;
  font-size: 40px;
  transition: opacity 0.3s;
}

.arrow:hover {
  opacity: 1;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

@media (max-width: 767px) {
  .collage-carousel {
    margin-top: 0px;
  }

  .collage-container {
    display: flex;
    gap: 0px;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
  }

  .collage-item {
    flex: 0 0 100px;
    height: 300px;
    border-radius: 0px;
    overflow: hidden;
  }

  .collage-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .modal img {
    max-width: 100%;
    max-height: 100%;
  }

  .modal .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 40px;
  }
}