.ReviewDiv {
  margin-bottom: 35px;

  h4 {
    font-size: 24px;
    line-height: .08em;
    font-family: Helvetica;
  }

  p {
    font-family: Helvetica;
    font-size: 16px;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .ReviewsCont {
    h2 {
      text-align: center;
    }
  }
  .ReviewDiv {
    h4 {
      font-size: 20px;
    }
  
    p {
      font-size: 14px;
    }
  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1200px) {}

/* Large screens
@media (min-width: 1201px) {
} */