.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.PaginationButton {
  background-color: transparent;
  color: black;
  border: none;
  border-radius: 50%;
  margin: 0 100px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  height: 30px;
  width: 30px;
}

.PageButton .PaginationButton {
  margin: 0 10px;

}

.PaginationButton:disabled {
  color: rgb(201, 201, 201);
  cursor: not-allowed;
}

.Pagination span {
  font-size: 16px;
  color: #333;
}

.PaginationButton.active {
  background-color: #6185F2;
  font-weight: bold;
  border-radius: 50%;
  color: white;
  text-align: center;
}

.PaginationButton:hover:not(:disabled):not(.active) {
  background-color: #CDD3E8;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .PaginationButton {
    margin: 0 25px;
  }
}