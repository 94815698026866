.RecentPropCont {
  text-align: center;
  width: 100%;
  background-color: rgb(232, 230, 230);
  padding: 50px 0;

  h2 {
    font-size: 40px;
    font-family: 'LuloClean';
  }
}

.PropPara {
  font-size: 18px;
  line-height: 1.8em;
  font-family: Helvetica;
}

/* Small screens */
@media (max-width: 767px) {
  .RecentPropCont {
    background-color: white;
    padding: 0;

    h2 {
      font-size: 30px;
    }

    p {
      font-size: 15px;
      padding-inline: 10px;
    }
  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1200px) {}

/* Large screens
@media (min-width: 1201px) {
} */