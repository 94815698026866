.BuySellCont {
  display: flex;
  justify-content: space-evenly;

  div {
    text-align: center;
  }

  strong {
    font-size: 15px;
    font-family: Helvetica, sans-serif;
  }

  p {
    font-family: Helvetica, sans-serif;
    line-height: 1.5em;
    font-size: 15px;
    text-align: start;
    margin-bottom: 50px;
  }
}

.BSMHeading {
  font-family: LuloClean;
  font-size: 40px;
  letter-spacing: .15em;
}

.Mortgage {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-family: Helvetica, sans-serif;
    margin-bottom: 50px;
    text-align: center;
    margin-top: 0;
  }
}

.StaticsMain {
  display: flex;
  width: 70%;
  justify-content: space-evenly;

  div {
    text-align: center;
    width: 33.33%;
  }

  strong {
    line-height: 1.4em;
    font-size: 22px;
    font-family: Helvetica, sans-serif;
  }

  h1 {
    font-size: 55px;
    font-family: LuloClean;
    color: #566FB8;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .BSMCont {
    display: flex;
    flex-direction: column-reverse;
  }

  .BuySellCont {
    flex-direction: column;
    padding-inline: 15px;

    p {
      word-spacing: 5px;
    }
  }

  .StaticsMain {
    width: 100%;
    background-color: #262626;
    flex-direction: column;
    padding: 15px 0;

    div {
      width: 100%;
    }
    strong {
      color: white;
    }
    h1 {
      font-size: 40px;
    }
  }

  .BSMHeading {
    font-size: 30px;
  }

  .Mortgage {
    text-align: center;

    p {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1200px) {
  .BuySellCont {
    padding-inline: 10px;
    gap: 20px;
  }

  .BSMHeading {
    font-size: 30px;
  }

  .StaticsMain {
    width: 95%;

    h1 {
      font-size: 45px;
    }
  }
}

/* Large screens
@media (min-width: 1201px) {
} */