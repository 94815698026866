.MainPageCon {
  position: relative;
  height: 911px;
  overflow: hidden;
}

.BgVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.DescDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.PersonBgImg {
  background-image: url('../../../Assets/Images/PersonBgImg.png');
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  margin: auto 0;
  background-position: calc(100% - 20px) calc(100% - 30%);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ContactCont {
  display: flex;
  align-items: center;
  height: 100%;
}

.MobContactDetailDiv {
  display: none;
}

.ContactDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: none;
  border-radius: 10px;
  font-family: Helvetica, sans-serif;
  color: black;
  padding: 34px 50px 34px 10px;
  margin-top: 100px;
  line-height: 1.7em;

  a {
    color: black;
  }
}

.AboutPersonDiv {
  background-position: left;
  background-image: url('../../../Assets/Images/AboutBgImg.png');
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;

  h3 {
    font-family: LuloClean;
    color: #6185F2;
    padding-left: 70px;
    margin-top: 171px;
    margin-bottom: 0;
    font-size: 16px;
  }

  h2 {
    font-family: LuloClean;
    color: white;
    font-size: 50px;
    margin-top: 0;
    letter-spacing: 0.05em;
    padding-left: 70px;
  }
}

.AboutCont {
  width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AboutPara {
  font-family: Helvetica, sans-serif;
  background-color: white;
  width: 438px;
  border-radius: 20px;
  padding: 20px 30px;

  p {
    font-size: 15px;
    line-height: 1.4em;
  }
}

.PersonImg {
  height: 722px;
  width: 458px;

}

/* Small screens */
@media (max-width: 767px) {

  .MainPageCon {
    background-image: url('../../../Assets/Images/MobMainPgBg.png');
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .BgVideo {
    display: none;
  }

  .DescDiv {
    flex-direction: column;
  }

  .AboutPersonDiv {
    width: 90%;
    background-image: none;
  }

  .AboutCont {
    width: 100%;
  }

  .ContactDiv {
    margin-top: 0px;
    padding: 15px;
  }

  .AboutPara {
    width: 95%;
    padding: 5px 10px;
    margin-bottom: 30px;
    p{text-align: left}
  }

  .PersonBgImg {
    background-image: none;
    width: 100%;
    justify-content: center;
  }

  .PersonImg {
    width: 247px;
    height: 400px;
  }

  .ContactCont {
    display: none;
  }

  .AbtPersonHeading {
    display: flex;
    flex-direction: column-reverse;
  }

  .AboutPersonDiv {
    text-align: center;

    h3 {
      padding-left: 0px;
      margin: 0px 0 25px 0;
    }

    h2 {
      font-size: 30px;
      padding-left: 0px;
      margin: 20px 0 10px 0;
    }
  }

  .MobContactDetailDiv {
    display: block;
    text-align: left;

  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1200px) {}

/* Large screens
@media (min-width: 1201px) {
} */